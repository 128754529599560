<template>
  <z-plate title="评选分类管理" has-bottom-border>
    <el-form class="add-group" label-width="80px" ref="form" :model="form">
      <el-form-item prop="name" label="分组名称">
        <el-input v-model="form.name" placeholder="请输入分组名称" class="auto-width" maxlength="50" disabled />
      </el-form-item>

      <el-form-item prop="jwids" label="评委">
        <el-select v-model="jwids" placeholder="请选择评委" class="auto-width" @change="customerChange" :disabled="this.form.id?true:false" >
          <el-option v-for="item in pwlist" :key="item.id" :label="item.label" :value="item.id"></el-option>
        </el-select>
      </el-form-item>

      <el-form-item prop="works" label="评选作品">
        <z-transfer :data="treeList" v-model="works" :size="leftCount" style="width: 650px"></z-transfer>
      </el-form-item>

      <el-form-item>
        <el-button type="primary" @click="handleSave">保存</el-button>
        <el-button @click="goBack">取消</el-button>
      </el-form-item>
    </el-form>
  </z-plate>
</template>

<script>
import ZTransfer from "./Transfer";
export default {
  components: {
    ZTransfer
  },

  data() {
    return {
      editFlag: true,
      leftCount: 0,
      jwids: [],
      works: [],
      treeList: [],
      pwlist: [],
      form: {
        id: sessionStorage.getItem("activity_groupid"),
        name: "",
        userids: '',
        videoids: '',
        activityId: sessionStorage.getItem("group_activityid")
      },
    };
  },
  created() {
    this.queryActivityPingWeiByActivityId();
    this.queryVideoTree();
    console.log('this.form.id----->', this.form.id);
    if (this.form.id) {
      this.queryGroupInfoByGroupId();
    }
  },
  methods: {
    customerChange(id){
      const thisLabel = this.pwlist.find(item => item.id === id).label;
      this.form.name = thisLabel;
      // console.log(`label----->`, thisLabel) //label-----> 王老师
    },
    goBack() {
      this.$router.push('/Administrator/Appraise/Group');
    },
    queryGroupInfoByGroupId() {
      this.$post('/queryGroupInfoByGroupId', { "groupid": this.form.id }).then(rep => {
        this.form.name = rep.content.groupInfo.name;
        this.jwids = rep.content.userids[0];
        this.works = rep.content.videoList;
      })
    },
    queryActivityPingWeiByActivityId() {
      this.$post('/queryActivityPingWeiByActivityId', { "activityid": this.form.activityId }).then(rep => {
        this.pwlist = rep.content.pwlist;
      })
    },
    queryVideoTree() {
      this.$post('/queryVideoTree', { "activityid": this.form.activityId }).then(rep => {
        this.treeList = rep.content.treeList;
        this.leftCount = rep.content.leftCount;
      })
    },
    handleSave() {
      if (!this.form.name) {
        this.$message({
          message: "请输入分组名称",
          type: "info",
          showClose: true
        });
        return;
      }

      if (!this.jwids.length) {
        this.$message({
          type: 'info',
          message: '请选择评委'
        });
        return;
      }

      if (!this.works.length) {
        this.$message({
          type: 'info',
          message: '请选择评选作品'
        });
        return;
      }

      if (this.editFlag) {
        this.editFlag = false;
        var videoid = new Array();
        for (var i = 0; i < this.works.length; i++) {
          videoid.push(this.works[i].id);
        }
        this.form.userids = this.jwids;
        this.form.videoids = videoid.join();
        this.$post('/saveGroupInfo', this.form).then(rep => {
          if(rep.resultCode == 0){
            this.editFlag = true;
            this.$message({
              type: 'success',
              message: '保存成功'
            });
            this.$router.go(-1);
          }else{
            this.$message({
							type: 'error',
							message: '操作失败!'
						});
          }
        });
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.add-group {
  padding: 20px;
}

</style>