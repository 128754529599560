<template>
  <div class="z-transfer">
    <!-- 左侧 -->
    <section class="z-transfer__left">
      <div class="z-transfer__inner">
        <p class="z-transfer__title clearfix">
          <!--<el-checkbox v-model="leftCheckbox">-->
          <span>{{ titles[0] }}（{{ leftSize }}）</span>
          <!--</el-checkbox>-->

          <!--<span class="z-transfer__size pull-right">{{leftSize}}</span>-->
        </p>

        <section class="z-transfer__filter">
          <el-input v-model="leftFilter" clearable :placeholder="filterPlaceholder" v-if="filterable" />
        </section>

        <el-scrollbar class="z-transfer__scroll">
          <el-tree ref="tree" v-if="currentData.length || showTree" :data="currentData" show-checkbox
            :node-key="props.id" :default-expanded-keys="defaultExpandedKeys" :props="props"
            :filter-node-method="filterNode" @check-change="handleCheckChange"></el-tree>

          <p class="z-transfer__empty" v-else>无数据</p>
        </el-scrollbar>
      </div>

      <!-- 按钮 -->
      <section class="z-transfer__btns">
        <div style="margin-bottom: 30px;">
          <el-button :class="{ 'el-button--primary': rightChecked.length !== 0 }" icon="el-icon-arrow-left"
            :disabled="rightChecked.length === 0" circle @click="wipeRightChecked"></el-button>
        </div>
        <div>
          <el-button :class="{ 'el-button--primary': leftChecked.length !== 0 }" icon="el-icon-arrow-right"
            :disabled="leftChecked.length === 0" circle @click="addToRight"></el-button>
        </div>
      </section>
    </section>

    <!-- 右侧 -->
    <section class="z-transfer__right">
      <div class="z-transfer__inner">
        <p class="z-transfer__title clearfix">
          <!--<el-checkbox v-model="rightCheckbox">-->
          <span>{{ titles[1] }}（{{ rightSize }}）</span>
          <!--</el-checkbox>-->

          <!--<span class="z-transfer__size pull-right">{{rightSize}}</span>-->
        </p>

        <section class="z-transfer__filter">
          <el-input v-model="rightFilter" clearable :placeholder="filterPlaceholder" v-if="filterable" />
        </section>

        <el-scrollbar class="z-transfer__scroll">
          <el-checkbox-group v-model="rightChecked" v-if="rightList.length">
            <el-checkbox :label="item" v-for="(item, index) in rightList" :key="index">{{ item[props.label] }}
            </el-checkbox>
          </el-checkbox-group>

          <p class="z-transfer__empty" v-else>无数据</p>
        </el-scrollbar>
      </div>
    </section>
  </div>
</template>

<script>
export default {
  name: "ZTransfer",

  data() {
    return {
      defaultExpandedKeys: [],
      rightChecked: [], //右侧已选择选择数组
      leftChecked: [],
      rightList: [], //右侧列表
      leftFilter: "",
      rightFilter: "",
      leftSize: 0,
      showTree: false,
      leftCheckbox: false,
      rightCheckbox: false
    };
  },

  props: {
    data: {
      type: Array,
      default: []
    },

    value: {
      type: Array,
      default: []
    },

    props: {
      type: Object,
      default: () => ({
        children: "children",
        label: "label",
        isLeaf: "leaf",
        id: "id"
      })
    },

    titles: {
      type: Array,
      default: () => ["备选作品", "已选"]
    },

    size: {
      type: Number,
      default: 0
    },

    filterable: {
      type: Boolean,
      default: true
    },

    filterPlaceholder: {
      type: String,
      default: "请输入搜索内容"
    },

    filterMethods: Function
  },

  computed: {
    currentData() {
      this.data.forEach(item => {
        this.defaultExpandedKeys.push(item[this.props.id]);
      });

      return this.data;
    },

    tree() {
      return this.$refs.tree;
    },

    rightSize() {
      return this.rightList.length;
    }
  },

  watch: {
    //左侧筛选
    leftFilter(value) {
      this.tree.filter(value);
    },

    //右侧筛选
    rightFilter(value) {
      this.rightList = this.value;
      this.rightList = this.rightList.filter(
        item => item[this.props.label].indexOf(value) !== -1
      );
    },

    //左侧全选
    leftCheckbox(val) {
      if (val) {
        this.data.forEach(item => {
          this.tree.setCheckedKeys([item[this.props.id]]);
        });
      } else {
        // eslint-disable-next-line no-unused-vars
        this.data.forEach(item => {
          this.tree.setCheckedKeys([]);
        });
      }
    },

    //右侧全选
    rightCheckbox(val) {
      if (val) {
        this.rightList.forEach(item => {
          this.rightChecked.push(item);
        });
      } else {
        this.rightChecked = [];
      }
    },

    value: {
      immediate: true,
      handler(val) {
        this.rightList = val;
      }
    },

    size: {
      immediate: true,
      handler(val) {
        this.leftSize = val;
      }
    }
  },

  methods: {
    // tree filter methods
    filterNode(value, data) {
      if (!value) return true;
      return data.label.indexOf(value) !== -1;
    },

    //tree event check change
    handleCheckChange() {
      this.leftChecked = this.tree.getCheckedNodes();
    },

    //删除右侧已选
    wipeRightChecked() {
      // 将右侧所有选中节点，添加到tree的相应的父节点下
      this.rightChecked.forEach(item => {
        const parent = item.parentKey;
        item.parentKey = null;
        this.tree.append(item, parent);
      });

      this.rightList = this.rightList.filter(item => {
        let arrlist = this.rightChecked.map(v => v[this.props.id]);
        return !arrlist.includes(item.id);
      });

      this.calculateSize(true, null);

      this.rightChecked = [];
      this.$emit("input", this.rightList);
    },

    //向右侧添加
    addToRight() {
      let { tree } = this;

      this.leftChecked = this.leftChecked.filter(
        item => item[this.props.isLeaf]
      );
      
      let Ids = this.rightList.map(item => item.id);
      var baoliu = [];
      
      for(let i = 0; i < this.leftChecked.length; i++){
        var item = this.leftChecked[i];
        if (!Ids.includes(item.id)) {
          this.rightList.push(item);
        }else{
          this.$message({
            type: 'info',
            message: '作品不能重复，重复项以去掉！'
          });
          baoliu.push(item);
          this.leftChecked.splice(item,i);
        }
      }

      // this.leftChecked.forEach(item => {
      //   if (!Ids.includes(item.id)) {
      //     this.rightList.push(item);
      //   }else{
      //     this.$message({
      //       type: 'info',
      //       message: '作品不能重复，重复项以去掉！'
      //     });
      //     this.leftChecked.splice(item);
      //   }
      // })
      // console.log(this.leftChecked.length)
      // this.rightList = this.unique(this.rightList.concat(this.leftChecked));

      if (this.leftChecked.length) {
        this.calculateSize(false, this.leftChecked.length);
      }
      // console.log('leftChecked = ', this.leftChecked);

      //记录当前节点的parent节点
      this.leftChecked.forEach(item => {
        item.parentKey = tree.getNode(item).parent.key;
      });
      //需要保留的节点
      var baoliuids = baoliu.map(item => item.id);
      //删除掉tree中相应的节点 
      this.rightList.forEach(item => {
        //console.log(item);
        if(!baoliuids.includes(item.id)){
          tree.remove(item);
        }
      });

      tree.setCheckedKeys([]);
      this.leftChecked = [];
      this.$emit("input", this.rightList);
    },

    calculateSize(type, obj) {
      if (type) {
        this.leftSize += this.rightChecked.length;
      } else {
        this.leftSize -= obj;
      }

      this.rightCheckbox = false;
      this.leftCheckbox = false;
    },

    // 去重
    unique(arr) {
      if (!Array.isArray(arr)) {
        // console.log("type error!");
        return;
      }
      let res = [],
        obj = {};
      for (let i = 0; i < arr.length; i++) {
        if (!obj[arr[i][this.props.id]]) {
          res.push(arr[i]);
          obj[arr[i][this.props.id]] = 1;
        } else {
          obj[arr[i][this.props.id]]++;
        }
      }
      return res;
    }
  }
};
</script>

<style lang="scss" scoped>
.z-transfer {
  @include clearfix;
  margin-bottom: 23px;

  .z-transfer__left,
  .z-transfer__right {
    position: relative;
    float: left;
    width: 50%;

    .z-transfer__inner {
      border: 1px solid #ebeef5;
      border-radius: 5px;
    }

    .z-transfer__scroll {
      height: 200px;
      overflow: hidden;

      &>/deep/ .el-scrollbar__wrap {
        overflow-x: auto;

        &>.el-scrollbar__view {
          padding: 0 15px 15px;
        }
      }

      .el-checkbox {
        display: block !important;
        margin-bottom: 5px;
      }
    }
  }

  .z-transfer__title {
    color: #666;
    padding: 10px 15px;
    background-color: #f5f8fb;
    border-bottom: 1px solid #ebeef5;
  }

  .z-transfer__size {
    color: #999;
    font-size: 12px;
  }

  .z-transfer__filter {
    padding: 15px;
  }

  .z-transfer__left {
    padding-right: 35px;
  }

  .z-transfer__right {
    padding-left: 35px;
  }

  .z-transfer__empty {
    color: #666;
    text-align: center;
    padding: 15px;
  }

  .z-transfer__btns {
    position: absolute;
    right: -18px;
    top: 40%;
    z-index: 9;

    /deep/ .el-button {
      width: 36px;
      height: 36px;
      padding: 10px;
    }
  }
}
</style>